import React from 'react';
import { Navigate } from 'react-router-dom';

import { sentryCreateBrowserRouter } from './modules/core/sentry/utils';
import { LandingRoute } from './modules/purchase/LandingRoute';

export function createRouter() {
  return sentryCreateBrowserRouter([
    {
      path: '/',
      element: <LandingRoute region="us" />
    },
    {
      path: '/ca',
      element: <LandingRoute region="ca" />
    },
    {
      path: '/partnerships',
      children: [
        {
          index: true,
          element: <Navigate to="/" replace />
        },
        {
          path: 'cpacanada',
          element: <LandingRoute region="ca" partnership="CPA Canada" />
        },
        {
          path: 'natp',
          element: <LandingRoute region="us" partnership="NATP" />
        }
      ]
    },
    {
      path: '*',
      element: <Navigate to="/" replace />
    },
  ]);
}
