import { PageTitle } from '../core/app/page-title';
import { Region } from './purchase-api';
import { PurchaseForm } from './PurchaseForm';


export function LandingRoute({ region, partnership }: { region: Region, partnership?: string }) {
  return (
    <>
      <PageTitle title={'Purchase'} />
      <div>
        <PurchaseForm region={region} partnership={partnership} />
      </div>
    </>
  )
}
